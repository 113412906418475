import React from 'react';
import { PaymentCheckout } from './PaymentCheckout';
import { withChallengeData } from '../../../../contexts/ChallengeDataProvider/withChallengeData';
import {
  serverPricingToClientPriceAndCurrencyString,
  serverTimelineTypeToClientTimelineString,
} from '@wix/challenges-web-library/dist/src';

import { withLocation } from '../../../../contexts/Location/withLocation';
import { withBI } from '../../../../contexts/BI/withBI';
import { ILocationProviderProps } from '../../../../contexts/Location/LocationProvider';
import {
  BIProviderPropsMap,
  ButtonNames,
} from '../../../../contexts/BI/interfaces';

import { useTranslation } from '@wix/yoshi-flow-editor';
import { IChallengeDataProps } from '../../../../contexts/ChallengeDataProvider/helpers';

export interface PaymentCheckoutContainerProps {}

type IPaymentCheckoutContainerProps = IChallengeDataProps &
  ILocationProviderProps &
  BIProviderPropsMap & { lng: string };

export const PaymentCheckoutContainer: React.FC<PaymentCheckoutContainerProps> =
  withLocation(
    withBI(
      withChallengeData((props: IPaymentCheckoutContainerProps) => {
        const { t } = useTranslation();
        const challenge = props.challengeData.challenge;

        const durationString = serverTimelineTypeToClientTimelineString(
          challenge.settings.timelineType as any,
          props.lng,
          t,
          'challenge-card.duration-string.ongoing',
          `challenge-card.duration-string.flexible.days_icu`,
          `challenge-card.duration-string.flexible.weeks_icu`,
          'challenge-card.duration-string.no-limit',
        );

        return (
          <PaymentCheckout
            t={t}
            showOneAppInfo={async () => {
              await props.memberWebAppButtonClick({
                buttonName: ButtonNames.OneAppInfo,
              });
              props.goToExternalUrl('https://www.wix.com/mobile/wix-app');
            }}
            title={challenge.settings.description.title}
            price={serverPricingToClientPriceAndCurrencyString(
              challenge.settings.pricing as any,
            )}
            duration={durationString}
          />
        );
      }),
    ),
  );
